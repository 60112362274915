.project-detail-view {
    animation: popUp;
    animation-duration: 0.35s;
}

.project-detail-view > .card{
    border-radius: 1.5rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-style: none;
    min-height: 85vh;
}

.project-detail-view .go-back {
    cursor: pointer;
    font-size:x-large;
    z-index: 200;
}

.project-detail-view h2 {
    font-weight:900;
    font-size: calc(1.5rem + 4.5vw);
}


.project-detail-view .background-img {
    position: absolute;
    margin-top: 50px;
    pointer-events:none;
    opacity: 0.03;
    width: 100%;
    max-height: 50rem;
}

.project-detail-view .card-content {
    min-height: 40vh;
}

/* Animations */
@keyframes popUp {
    0% {transform: scale(0.9);}
    100% {transform: scale(1);}
}

/* Printview */
@media print {
    .project-detail-view {
        margin: 0;
        padding: 0;
        -webkit-print-color-adjust: exact;
    }

    .project-detail-view h2 {
        font-size: 6rem
    }

    .project-detail-view h3 {
        font-size: 3rem;
        font-weight: 800;
        margin-bottom: 10px;
    }

    .project-detail-view h5 {
        font-size: 1.7rem !important;
        margin-top: 1.4rem;
    }

    .project-detail-view .col-12 {
        width: 100%;
    }

    .project-detail-view .card-content {
        height: 40vh;
        margin-top: 1rem !important;
    }

    .project-detail-view .print-fallback {
        font-size: 1.7rem !important;
    }

    .project-detail-view > .card{
        width: 100%;
        height: 100vh;
        margin: 0 !important;
        border-radius: 0;
        box-shadow: none;
        border-style: none;
    }

    .project-detail-view .colorDisplay {
        font-size: 1.5rem;
    }
}
