.project-card {
    border-radius: 1.1rem;
    border-width: 0;
    width: 11rem;
    height: 11rem;  
    box-shadow: rgba(115, 129, 141, 0.2) 0px 8px 24px;
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s;
    font-size: 0.7rem;
    margin: 1rem;
  }

  .project-card .card-title  {
    font-size: 0.85rem;
    font-weight: 600;
  }

  .done {
    color: #585959;
  }

  .done img {
    filter: grayscale(90%);
  }

  .project-card img {
    width: auto;
    height: 2.1rem;
    object-fit:contain;
  }
  
  .project-card:hover {
    box-shadow: 0 30px 40px -10px rgba(5, 18, 62, 0.15);
    transform: scale(1.02);
    cursor: pointer;
  }