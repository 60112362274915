.chart-wrapper {
    padding: 2rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-width: 0.1rem;
    border-radius: 1.1rem;
    overflow-x: visible;
    max-width: 100%;
    overflow-x: hidden;
}


/* rounded borders */
#timeline-chart g rect {
    rx: 7px;
    ry: 7px;
    opacity: 90%;
}

#timeline-chart g rect:hover{
    cursor: pointer;
}

/* different stroke color of frame */
#timeline-chart div > div:nth-child(1) > div > svg > g:nth-child(2) > rect:last-child {
    stroke: transparent;
}

/* to prevent vertical scrollbar */
html, body {
    max-width: 100%;
    overflow-x: hidden;
}
