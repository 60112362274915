.persons-display .card {
    border-radius: 0.5rem;
    box-shadow: rgba(100, 100, 111, 0.15) 0px 5px 22px 0px;
    border-style: none;
    transition: .1s transform cubic-bezier(.155,1.105,.295,1.12),.1s;
    width: 15rem;
}

.person-display p {
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    width: 11rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.person-display i {
    color: #535353;
}

.person-display:hover {
    transform: scale(1.02);
}
